import { notFound } from "next/navigation"
import { Address, PropertyStats, PropertyType } from "@/db/schemas"
import { PROPERTY_TYPES } from "@/db/schemas/constants/property-types"
import { PropertyTypeEnum, TransactionTypeEnum } from "@/db/schemas/enums"
import { PropertySort, SortOptionEnum, WithLabel } from "@/types"
import { isClerkAPIResponseError } from "@clerk/nextjs"
import type { User } from "@clerk/nextjs/server"
import { ClerkAPIError, UserResource } from "@clerk/types"
import * as Sentry from "@sentry/nextjs"
import { clsx, type ClassValue } from "clsx"
import { toast } from "sonner"
import { twMerge } from "tailwind-merge"
import { any, Schema, z } from "zod"

import { addressFormSchema } from "@/modules/publish-flow/validations/publish-flow"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatPrice(
  price: number | string,
  options: {
    currency?: "USD" | "EUR" | "GBP" | "BDT" | "PEN"
    notation?: Intl.NumberFormatOptions["notation"]
  } = {}
) {
  const { currency = "USD", notation = "compact" } = options

  if (currency === "PEN" && notation === "compact") {
    return `s/. ${Number(price).toLocaleString("en-US")}`
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    notation,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(price))
}

export function formatBytes(
  bytes: number,
  decimals = 0,
  sizeType: "accurate" | "normal" = "normal"
) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"]
  if (bytes === 0) return "0 Byte"
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === "accurate" ? accurateSizes[i] ?? "Bytes" : sizes[i] ?? "Bytes"
  }`
}

export function formatPhoneNumber(phone: string) {
  // format: (51) 999 999 999
  const cleaned = phone.replace(/\D/g, "")
  const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{3})$/)
  if (!match) return phone
  return `(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`
}

export function isArrayOfFile(files: unknown): files is File[] {
  const isArray = Array.isArray(files)
  if (!isArray) return false
  return files.every((file) => file instanceof File)
}

export function getUserEmail(user: User | UserResource | null | undefined) {
  const email =
    user?.emailAddresses?.find((e) => e.id === user.primaryEmailAddressId)
      ?.emailAddress ?? ""

  return email
}

export function catchError(
  err: unknown,
  options?: {
    captureSentry?: boolean
    showToast?: boolean
  }
) {
  const { captureSentry = false, showToast = true } = options ?? {}
  captureSentry && Sentry.captureException(err)
  if (!showToast) return
  if (err instanceof z.ZodError) {
    const errors = err.issues.map((issue) => {
      return issue.message
    })
    return toast.error(errors.join("\n"))
  } else if (err instanceof Error) {
    return toast.error(err.message)
  } else {
    return toast.error("Algo salió mal, por favor intenta de nuevo más tarde.")
  }
}

export function catchClerkError(err: unknown) {
  const unknownErr = "Algo salió mal, por favor intenta de nuevo más tarde."
  if (err instanceof z.ZodError) {
    const errors = err.issues.map((issue) => {
      return issue.message
    })
    return toast.error(errors.join("\n"))
  } else if (isClerkAPIResponseError(err)) {
    return showToastMessageFromClerkError(err.errors)
  } else {
    return toast.error(unknownErr)
  }
}

function showToastMessageFromClerkError(err: ClerkAPIError[]) {
  const error = err[0]
  if (error?.code === "strategy_for_user_invalid") {
    return toast.error(
      "El email introducido ha sido registrado con otro método de autenticación (Google o Facebook). Por favor, intenta con otro email o método de autenticación."
    )
  } else if (error?.code === "form_identifier_exists") {
    return toast.error(
      "El email introducido ya está registrado. Por favor, intenta con otro email o inicia sesión."
    )
  } else if (error?.code === "form_password_incorrect") {
    return toast.error("El email o contraseña introducidos no son correctos.")
  } else if (error?.code === "form_identifier_not_found") {
    return toast.error("El email introducido no está registrado.")
  } else if (error?.code === "form_code_incorrect") {
    return toast.error("El código de verificación introducido no es correcto.")
  } else if (error?.code === "form_password_validation_failed") {
    return toast.error("La contraseña actual introducida es incorrecta")
  } else if (error?.code === "form_password_pwned") {
    return toast.error(
      "La contraseña ha sido encontrada en una violación de datos online. Por la seguridad de su cuenta, utilice una contraseña diferente."
    )
  } else {
    return toast.error(error?.longMessage)
  }
}

export function validateNumericInput({
  min = 0,
  max = 999,
  nullish = false,
}: {
  min?: number
  max?: number
  nullish?: boolean
}) {
  const validation = z.preprocess(
    (input) => {
      const processed = z
        .string()
        .transform((val) => Number(val.replace(/[^\d.-]/g, "")))
        .safeParse(input)
      return processed.success ? processed.data : input
    },
    z
      .number({
        required_error: "Obligatorio",
      })
      .min(min)
      .max(max, {
        message:
          "Este valor no parece ser correcto, es demasiado grande, por favor revísalo",
      })
  )
  return nullish ? validation.nullish() : validation
}

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 * If the schema fails, it will return the fallback value
 * @param schema
 * @param value
 * @returns
 */
export function zodFallback<T, U>(schema: Schema<T>, value: U): Schema<T | U> {
  return any().transform((val) => {
    const safe = schema.safeParse(val)
    return safe.success ? safe.data : value
  })
}

export function parsePlaceToAddress(
  place: google.maps.places.PlaceResult
): z.infer<typeof addressFormSchema> {
  return {
    placeId: place.place_id ?? "",
    formattedAddress: place.formatted_address ?? "",
    postalCode:
      place.address_components?.find((c) => c.types.includes("postal_code"))
        ?.long_name ?? "",
    country:
      place.address_components?.find((c) => c.types.includes("country"))
        ?.long_name ?? "",
    administrativeAreaLevel1:
      place.address_components?.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.long_name ?? "",
    administrativeAreaLevel2:
      place.address_components?.find((c) =>
        c.types.includes("administrative_area_level_2")
      )?.long_name ?? "",
    administrativeAreaLevel3: place.address_components?.find((c) =>
      c.types.includes("administrative_area_level_3")
    )?.long_name,
    administrativeAreaLevel4: place.address_components?.find((c) =>
      c.types.includes("administrative_area_level_4")
    )?.long_name,
    administrativeAreaLevel5: place.address_components?.find((c) =>
      c.types.includes("administrative_area_level_5")
    )?.long_name,
    administrativeAreaLevel6: place.address_components?.find((c) =>
      c.types.includes("administrative_area_level_6")
    )?.long_name,
    administrativeAreaLevel7: place.address_components?.find((c) =>
      c.types.includes("administrative_area_level_7")
    )?.long_name,
    streetAddress: place.address_components?.find((c) =>
      c.types.includes("street_address")
    )?.long_name,
    locality:
      place.address_components?.find((c) => c.types.includes("locality"))
        ?.long_name ?? "",
    sublocality: place.address_components?.find((c) =>
      c.types.includes("sublocality")
    )?.long_name,
    neighborhood: place.address_components?.find((c) =>
      c.types.includes("neighborhood")
    )?.long_name,
    route:
      place.address_components?.find((c) => c.types.includes("route"))
        ?.long_name ?? "",
    streetNumber: place.address_components?.find((c) =>
      c.types.includes("street_number")
    )?.long_name,
    premise: place.address_components?.find((c) => c.types.includes("premise"))
      ?.long_name,
    subpremise: place.address_components?.find((c) =>
      c.types.includes("subpremise")
    )?.long_name,
    latitude:
      (typeof place.geometry?.location?.lat === "function"
        ? place.geometry?.location?.lat()
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          (place.geometry?.location?.lat as number)) ?? null,
    longitude:
      (typeof place.geometry?.location?.lng === "function"
        ? place.geometry?.location?.lng()
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          (place.geometry?.location?.lng as number)) ?? null,
    auxAddress: [
      place.address_components?.find((c) => c.types.includes("route"))
        ?.long_name,
      place.address_components?.find((c) => c.types.includes("street_number"))
        ?.long_name,
    ]
      .filter(Boolean)
      .join(" "),
  }
}

export function parseAddressToDefaultForm(
  address: Address
): z.infer<typeof addressFormSchema> {
  return {
    placeId: address.placeId ?? "",
    formattedAddress: address.formattedAddress ?? "",
    postalCode: address.postalCode ?? "",
    country: address.country ?? "",
    administrativeAreaLevel1: address.administrativeAreaLevel1 ?? "",
    administrativeAreaLevel2: address.administrativeAreaLevel2 ?? "",
    administrativeAreaLevel3: address.administrativeAreaLevel3 ?? "",
    administrativeAreaLevel4: address.administrativeAreaLevel4 ?? "",
    administrativeAreaLevel5: address.administrativeAreaLevel5 ?? "",
    administrativeAreaLevel6: address.administrativeAreaLevel6 ?? "",
    administrativeAreaLevel7: address.administrativeAreaLevel7 ?? "",
    streetAddress: address.streetAddress ?? "",
    locality: address.locality ?? "",
    sublocality: address.sublocality ?? "",
    neighborhood: address.neighborhood ?? "",
    route: address.route ?? "",
    streetNumber: address.streetNumber ?? "",
    premise: address.premise ?? "",
    subpremise: address.subpremise ?? "",
    latitude: address.latitude ?? null,
    longitude: address.longitude ?? null,
    auxAddress: [address.route, address.streetNumber].filter(Boolean).join(" "),
  }
}

export const getElapsedTime = (date: Date) => {
  const now = new Date()
  const elapsed = now.getTime() - date.getTime()

  // if less than 1 hour return minutes
  if (elapsed < 3600000) {
    let minutes = Math.floor(elapsed / 60000)
    if (minutes === 0) minutes = 1
    return `${minutes} ${minutes === 1 ? "minuto" : "minutos"}`
  }

  // if less than 24h return hours
  if (elapsed < 86400000) {
    const hours = Math.floor(elapsed / 3600000)
    return `${hours} ${hours === 1 ? "hora" : "horas"}`
  }

  // if less than 30 days return days
  if (elapsed < 2592000000) {
    const days = Math.floor(elapsed / 86400000)
    return `${days} ${days === 1 ? "dia" : "días"}`
  }

  // if less than 12 months return months
  if (elapsed < 31536000000) {
    const months = Math.floor(elapsed / 2592000000)
    return `${months} ${months === 1 ? "mes" : "meses"}`
  }

  // else return years
  const years = Math.floor(elapsed / 31536000000)
  return `${years} ${years === 1 ? "año" : "años"}`
}

export const getVideoIdFromYoutubeUrl = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7]?.length == 11 ? match[7] : false
}

export const parseParamsToSort = (
  searchParams: {
    [key: string]: string | string[] | undefined
  },
  defaultField?: SortOptionEnum
): PropertySort => {
  const sort =
    searchParams.ordenar_por ?? defaultField ?? SortOptionEnum.Values.recientes

  switch (sort) {
    case SortOptionEnum.Values.baratos:
      return new PropertySort("price", "ASC")
    case SortOptionEnum.Values.caros:
      return new PropertySort("price", "DESC")
    case SortOptionEnum.Values.antiguos:
      return new PropertySort("createdAt", "ASC")
    case SortOptionEnum.Values.recientes:
      return new PropertySort("createdAt", "DESC")
    case SortOptionEnum.Values.relevancia:
      return new PropertySort("score", "DESC")
    default:
      return new PropertySort()
  }
}

export const calculateTotalSharedStats = (stats: PropertyStats) =>
  (stats?.sharedCopyLink ?? 0) +
  (stats?.sharedWhatsapp ?? 0) +
  (stats?.sharedFacebook ?? 0) +
  (stats?.sharedFacebookMessenger ?? 0)

export const calculateTotalContactShownStats = (stats: PropertyStats) =>
  (stats?.shownEmail ?? 0) +
  (stats?.shownPhone ?? 0) +
  (stats?.shownWhatsapp ?? 0)

export const prettifyLabel = (label: string) =>
  capitalize(label.split("-").join(" ").replace(/anos /i, "años "))

export const normalizeText = (text: string) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()

export const sanitizeAndNormalizeText = (text: string) =>
  normalizeText(text.trim().toLowerCase().replaceAll(" ", "-"))

export const calculatePercentageDiff = (a: number, b: number) =>
  100 * Math.abs((a - b) / ((a + b) / 2))

export const stepPaths = {
  1: "operacion-y-propiedad",
  2: "direccion",
  3: "direccion",
  4: "contacto",
  5: "caracteristicas",
  6: "extras",
  7: "media",
  8: "resumen",
}

/**
 * Calculates the haversine distance between point A, and B.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 */
export const haversineDistance = (
  [lat1, lon1]: (number | null)[],
  [lat2, lon2]: (number | null)[]
) => {
  const RADIUS_OF_EARTH_IN_KM = 6371
  if (!lat1 || !lon1 || !lat2 || !lon2) return RADIUS_OF_EARTH_IN_KM
  const toRadian = (angle: number) => (Math.PI / 180) * angle
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b)

  const dLat = distance(lat2, lat1)
  const dLon = distance(lon2, lon1)

  lat1 = toRadian(lat1)
  lat2 = toRadian(lat2)

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.asin(Math.sqrt(a))

  const finalDistance = RADIUS_OF_EARTH_IN_KM * c

  return finalDistance
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export const isMobileDevice = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export const batchImagesBySize = (
  images: File[],
  maxSize: number = 4 * 1024 * 1024 // 4MB
) => {
  const batches: File[][] = []
  let currentBatch: File[] = []
  let currentSize = 0

  for (const image of images) {
    const size = image.size
    if (currentSize + size > maxSize) {
      batches.push(currentBatch)
      currentBatch = [image]
      currentSize = size
    } else {
      currentBatch.push(image)
      currentSize += size
    }
  }

  if (currentBatch.length > 0) {
    batches.push(currentBatch)
  }

  return batches
}

export function parseURLSearchParams(searchParams: URLSearchParams): {
  [key: string]: string | string[] | undefined
} {
  const result: { [key: string]: string | string[] | undefined } = {}
  searchParams.forEach((value, key) => {
    if (result[key]) {
      if (Array.isArray(result[key])) {
        ;(result[key] as string[]).push(value)
      } else {
        result[key] = [result[key] as string, value]
      }
    } else {
      result[key] = value
    }
  })
  return result
}

export const removeNumbers = (str: string) =>
  str
    .replace(/\d+/g, "")
    .replaceAll(", ,", ",")
    .replaceAll(" ,", ", ")
    .replaceAll(",", ", ")
    .replaceAll(" , ", ", ")

export const validateCoreTypeParams = (params: {
  transactionType: string
  propertyType: string
}) => {
  const { transactionType, propertyType } = params
  if (
    (transactionType && !(transactionType in TransactionTypeEnum.Values)) ||
    (propertyType && !(propertyType in PropertyTypeEnum.Values))
  )
    return notFound()
}

export const minifyHtml = (html: string): string =>
  html.replace(/\<\!--\s*?[^\s?\[][\s\S]*?--\>/g, "").replace(/\>\s*\</g, "><")

export const getPropertyTypeWithLabel = (
  propertyType: PropertyTypeEnum
): WithLabel<PropertyType> | undefined => {
  return PROPERTY_TYPES.find((t) => t.key === propertyType)
}

export const preprocessZodNumber = (
  options: { min?: number; max?: number; isNullish?: boolean } = {
    min: 1,
    max: 999_999,
  }
) => {
  const numberSchema = z
    .number({
      required_error: "Rellena este campo",
    })
    .min(options.min ?? 1, {
      message: "Este valor no parece ser correcto, por favor revísalo",
    })
    .max(options.max ?? 999_999, {
      message:
        "Este valor no parece ser correcto, es demasiado grande, por favor revísalo",
    })
  if (options.isNullish) numberSchema.nullish()

  return z.preprocess((input) => {
    const processed = z
      .string()
      .transform((val) => Number(val.replace(/[^\d.-]/g, "")))
      .safeParse(input)
    return processed.success ? processed.data : input
  }, numberSchema)
}

export function getDefaults<Schema extends z.AnyZodObject>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()]
      return [key, undefined]
    })
  )
}

export function getFirstDayOfFutureMonth(monthsInAdvance: number): string {
  const today = new Date()
  const futureDate = new Date(
    today.getFullYear(),
    today.getMonth() + monthsInAdvance,
    1
  )

  const day = futureDate.getDate()
  const month = futureDate.getMonth() + 1 // Months are zero-based
  const year = futureDate.getFullYear()

  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`

  return formattedDate
}
