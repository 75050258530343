import { z } from "zod"

export const MembershipPlan = [
  "individual",
  "professional",
  "enterprise",
] as const
export const MembershipPlanEnum = z.enum(MembershipPlan)
export type MembershipPlanEnum = z.infer<typeof MembershipPlanEnum>

export const PropertyType = [
  "departamento",
  "casa",
  "terreno",
  "local-comercial",
  "oficina",
  "habitacion",
] as const
export const PropertyTypeEnum = z.enum(PropertyType)
export type PropertyTypeEnum = z.infer<typeof PropertyTypeEnum>

export const TransactionType = ["alquiler", "venta"] as const
export const TransactionTypeEnum = z.enum(TransactionType)
export type TransactionTypeEnum = z.infer<typeof TransactionTypeEnum>

export const FeatureGroup = [
  "caracteristicas",
  "dormitorios",
  "banos",
  "estacionamientos",
  "tipo-de-departamento",
  "amueblado",
  "antiguedad",
  "deposito-alquiler",
  "mantenimiento",
  "vista",
  "tipo-de-terreno",
  // ------------
  "planta",
  "puerta",
  // ------------
  "ambientes",
  "escaparates",
  "estado",
  "actividad-comercial",
  "ubicacion-comercial",
  "numero-de-plantas",
  // ---- Habitación en Vivienda Compartida ----
  "fumadores",
  "mascotas",
  "parejas",
  "menores",
  "habitacion-amoblada",
  "baño-habitacion",
  "personas-totales-en-vivienda",
  "ocupacion-profesional",
  "quien-vive-actualmente",
  "propietario-vive-en-la-vivienda",
  "convivencia",
  "genero-deseado",
  "ocupacion-deseada",
  "estancia-minima",
  "disponible-a-partir-de",
  "generos-de-las-personas-que-viven-actualmente",
  "rango-edad-deseado",
] as const
export const FeatureGroupEnum = z.enum(FeatureGroup)
export type FeatureGroupEnum = z.infer<typeof FeatureGroupEnum>

export const bedroomFeatures = [
  "0-dormitorios",
  "1-dormitorio",
  "2-dormitorios",
  "3-dormitorios",
  "mas-de-4-dormitorios",
] as const
export const BedroomFeaturesEnum = z.enum(bedroomFeatures)
export type BedroomFeaturesEnum = z.infer<typeof BedroomFeaturesEnum>

export const bathroomFeatures = [
  "0-banos",
  "1-bano",
  "2-banos",
  "3-banos",
  "mas-de-4-banos",
] as const
export const BathroomFeaturesEnum = z.enum(bathroomFeatures)
export type BathroomFeaturesEnum = z.infer<typeof BathroomFeaturesEnum>

export const roomsFeatures = [
  "0-ambientes",
  "1-ambiente",
  "2-ambientes",
  "3-ambientes",
  "mas-de-4-ambientes",
] as const
export const RoomsFeaturesEnum = z.enum(roomsFeatures)
export type RoomsFeaturesEnum = z.infer<typeof RoomsFeaturesEnum>

export const totalFloorFeatures = [
  "1-planta",
  "2-plantas",
  "3-plantas",
  "mas-de-4-plantas",
] as const
export const TotalFloorsFeaturesEnum = z.enum(totalFloorFeatures)
export type TotalFloorsFeaturesEnum = z.infer<typeof TotalFloorsFeaturesEnum>

export const shopWindowsFeatures = [
  "0-escaparates",
  "1-escaparate",
  "2-escaparates",
  "3-escaparates",
  "mas-de-4-escaparates",
] as const
export const ShopWindowsFeaturesEnum = z.enum(shopWindowsFeatures)
export type ShopWindowsFeaturesEnum = z.infer<typeof ShopWindowsFeaturesEnum>

const parkingFeatures = [
  "0-estacionamientos",
  "1-estacionamiento",
  "2-estacionamientos",
  "3-estacionamientos",
  "mas-de-4-estacionamientos",
] as const
export const ParkingFeaturesEnum = z.enum(parkingFeatures)
export type ParkingFeaturesEnum = z.infer<typeof ParkingFeaturesEnum>

export const apartmentTypeFeatures = ["flat", "duplex", "triplex"] as const
export const ApartmentTypeFeaturesEnum = z.enum(apartmentTypeFeatures)
export type ApartmentTypeFeaturesEnum = z.infer<
  typeof ApartmentTypeFeaturesEnum
>

export const furnishedFeatures = [
  "sin-amoblar",
  "cocina-equipada",
  "semi-amoblado",
  "amoblado",
] as const
export const FurnishedFeaturesEnum = z.enum(furnishedFeatures)
export type FurnishedFeaturesEnum = z.infer<typeof FurnishedFeaturesEnum>

const buildingAgeFeatures = [
  "anos-de-antiguedad",
  "a-estrenar",
  "en-construccion",
  "en-planos",
] as const
export const BuildingAgeFeaturesEnum = z.enum(buildingAgeFeatures)
export type BuildingAgeFeaturesEnum = z.infer<typeof BuildingAgeFeaturesEnum>

export const depositFeatures = [
  "1-mes-de-garantia",
  "2-meses-de-garantia",
  "3-meses-de-garantia",
  "4-meses-de-garantia",
] as const
export const DepositFeaturesEnum = z.enum(depositFeatures)
export type DepositFeaturesEnum = z.infer<typeof DepositFeaturesEnum>

export const propertyConditions = ["bueno", "a-reformar"] as const
export const PropertyConditionEnum = z.enum(propertyConditions)
export type PropertyConditionEnum = z.infer<typeof PropertyConditionEnum>

const maintenanceFeatures = [
  "incluye-mantenimiento",
  "no-incluye-mantenimiento",
] as const
export const MaintenanceFeaturesEnum = z.enum(maintenanceFeatures)
export type MaintenanceFeaturesEnum = z.infer<typeof MaintenanceFeaturesEnum>

const viewFeatures = [
  "vista-a-ambas",
  "vista-a-interior",
  "vista-a-exterior",
] as const
export const ViewFeaturesEnum = z.enum(viewFeatures)
export type ViewFeaturesEnum = z.infer<typeof ViewFeaturesEnum>

export const terrainTypeFeatures = [
  "urbanizado",
  "urbanizable",
  "no-urbanizable",
] as const
export const TerrainTypeFeaturesEnum = z.enum(terrainTypeFeatures)
export type TerrainTypeFeaturesEnum = z.infer<typeof TerrainTypeFeaturesEnum>

export const commercialLocations = [
  "en-edificio",
  "en-centro-comercial",
  "en-galeria",
  "en-avenida-o-calle",
  "sotano",
  "otros",
] as const
export const CommercialLocationEnum = z.enum(commercialLocations)
export type CommercialLocationEnum = z.infer<typeof CommercialLocationEnum>

export const commercialActivities = [
  "bed-&-breakfast",
  "hotel",
  "negocio-de-turismo-rural",
  "otros-tipos-de-alojamiento",
  "bar",
  "bar-de-copas",
  "cafeteria",
  "discoteca",
  "pub",
  "asador",
  "bocateria",
  "chiringuito",
  "pizzeria",
  "restaurante",
  "take-away",
  "otros-tipos-de-restauracion",
  "carniceria",
  "fruteria",
  "heladeria",
  "panaderia",
  "pasteleria",
  "supermercado",
  "tienda-de-alimentacion",
  "vinoteca",
  "otros-tipos-de-comercio-de-alimentacion",
  "estanco",
  "farmacia",
  "kiosco",
  "concesionario",
  "ferreteria",
  "floristeria",
  "herbolario",
  "papeleria",
  "tienda-de-cosmetica-y-perfumeria",
  "tienda-de-electronica-e-informatica",
  "tienda-de-menaje-de-hogar",
  "tienda-de-ropa",
  "otros-tipos-de-tienda",
  "centro-educativo",
  "centro-estetico",
  "peluqueria",
  "spa",
  "centro-fitness",
  "gimnasio",
  "agencia-inmobiliaria",
  "otras-clinicas",
  "taller",
  "otros-tipos-de-servicios-profesionales",
  "laboratorio",
  "almacen",
  "otras-actividades",
  "clinica-dental",
  "clinica-de-fisioterapia",
  "clinica-veterinaria",
  "empresa-agricola",
] as const
export const CommercialActivityEnum = z.enum(commercialActivities)
export type CommercialActivityEnum = z.infer<typeof CommercialActivityEnum>

const doorFeatures = [
  "letra-abc",
  "numero-123",
  "puerta-unica",
  "puerta-izquierda",
  "puerta-derecha",
] as const
export const DoorFeaturesEnum = z.enum(doorFeatures)
export type DoorFeaturesEnum = z.infer<typeof DoorFeaturesEnum>

const extrasFeatures = [
  "ascensor",
  "admite-mascotas",
  "balcon",
  "terraza",
  "videovigilancia",
  "vigilancia",
  "jardin",
  "lavanderia",
  "piscina",
  "areas-comunes",
  "deposito-trastero",
  "lujo",
  "vista-al-mar",
  "en-condominio",
  "recepcion",
  // ----- Local Comercial -----
  "salida-de-humos",
  "cocina-completamente-equipada",
  "sistema-de-alarma",
  "detectores-de-humo",
  "area-cafe",
  "comedor",
  "sala-multiusos",
  "aire-acondicionado",
  "adaptado-movilidad-reducida",
  "estacionamiento-bicicletas",
  // ----- Habitación en Vivienda Compartida -----
  "internet",
  "limpieza-incluida",
  "habitacion-amueblada",
  "armario-empotrado",
  "baño-privado",
  "lgtb-friendly",
] as const
export const ExtrasFeaturesEnum = z.enum(extrasFeatures)
export type ExtrasFeaturesEnum = z.infer<typeof ExtrasFeaturesEnum>

const floorFeatures = ["planta-1a", "planta-2a", "planta-3a"] as const
export const FloorFeaturesEnum = z.enum(floorFeatures)
export type FloorFeaturesEnum = z.infer<typeof FloorFeaturesEnum>

const smokersFeatures = ["se-permite-fumar", "no-se-permite-fumar"] as const
export const SmokersFeaturesEnum = z.enum(smokersFeatures)
export type SmokersFeaturesEnum = z.infer<typeof SmokersFeaturesEnum>

const petsFeatures = [
  "se-permiten-mascotas",
  "no-se-permiten-mascotas",
] as const
export const PetsFeaturesEnum = z.enum(petsFeatures)
export type PetsFeaturesEnum = z.infer<typeof PetsFeaturesEnum>

const couplesAllowedFeatures = [
  "se-aceptan-parejas",
  "no-se-aceptan-parejas",
] as const
export const CouplesAllowedFeaturesEnum = z.enum(couplesAllowedFeatures)
export type CouplesAllowedFeaturesEnum = z.infer<
  typeof CouplesAllowedFeaturesEnum
>

const minorsAllowedFeatures = [
  "se-aceptan-menores-de-edad",
  "no-se-aceptan-menores-de-edad",
] as const
export const MinorsAllowedFeaturesEnum = z.enum(minorsAllowedFeatures)
export type MinorsAllowedFeaturesEnum = z.infer<
  typeof MinorsAllowedFeaturesEnum
>

const bedroomIsFurnishedFeatures = [
  "habitacion-amoblada",
  "habitacion-no-amoblada",
] as const
export const BedroomIsFurnishedFeaturesEnum = z.enum(bedroomIsFurnishedFeatures)
export type BedroomIsFurnishedFeaturesEnum = z.infer<
  typeof BedroomIsFurnishedFeaturesEnum
>

const bedroomBathroomFeatures = [
  "habitacion-con-baño-privado",
  "habitacion-con-baño-compartido",
] as const
export const BedroomBathroomFeaturesEnum = z.enum(bedroomBathroomFeatures)
export type BedroomBathroomFeaturesEnum = z.infer<
  typeof BedroomBathroomFeaturesEnum
>

// const typeOfBedFeatures = [
//   "cama-individual",
//   "cama-doble",
//   "dos-camas",
//   "sin-cama",
// ] as const
// export const TypeOfBedFeaturesEnum = z.enum(typeOfBedFeatures)
// export type TypeOfBedFeaturesEnum = z.infer<typeof TypeOfBedFeaturesEnum>

const totalPeopleInHouseFeatures = ["personas-totales-en-vivienda"] as const
export const TotalPeopleInHouseFeaturesEnum = z.enum(totalPeopleInHouseFeatures)
export type TotalPeopleInHouseFeaturesEnum = z.infer<
  typeof TotalPeopleInHouseFeaturesEnum
>

const genderOfPeopleCurrentlyLiving = [
  "viven-hombres-y-mujeres",
  "viven-solo-hombres",
  "viven-solo-mujeres",
] as const
export const GenderOfPeopleCurrentlyLivingEnum = z.enum(
  genderOfPeopleCurrentlyLiving
)
export type GenderOfPeopleCurrentlyLivingEnum = z.infer<
  typeof GenderOfPeopleCurrentlyLivingEnum
>

const professionalOccupation = [
  "estudiantes",
  "con-trabajo",
  "estudiantes-y-con-trabajo",
] as const
export const ProfessionalOccupationEnum = z.enum(professionalOccupation)
export type ProfessionalOccupationEnum = z.infer<
  typeof ProfessionalOccupationEnum
>

const whoLivesCurrently = ["vive-alguien", "aun-no-vive-nadie"] as const
export const WhoLivesCurrentlyEnum = z.enum(whoLivesCurrently)
export type WhoLivesCurrentlyEnum = z.infer<typeof WhoLivesCurrentlyEnum>

const ownerLivesInHouse = [
  "propietario-vive-en-la-vivienda",
  "propietario-no-vive-en-la-vivienda",
] as const
export const OwnerLivesInHouseEnum = z.enum(ownerLivesInHouse)
export type OwnerLivesInHouseEnum = z.infer<typeof OwnerLivesInHouseEnum>

const coexistence = [
  "convivencia-tranquila",
  "convivencia-amistosa",
  "convivencia-animada",
] as const
export const CoexistenceEnum = z.enum(coexistence)
export type CoexistenceEnum = z.infer<typeof CoexistenceEnum>

const desiredGender = [
  "genero-deseado-indiferente",
  "genero-deseado-hombre",
  "genero-deseado-mujer",
] as const
export const DesiredGenderEnum = z.enum(desiredGender)
export type DesiredGenderEnum = z.infer<typeof DesiredGenderEnum>

const desiredOccupation = [
  "ocupacion-deseada-indiferente",
  "ocupacion-deseada-estudiante",
  "ocupacion-deseada-con-trabajo",
] as const
export const DesiredOccupationEnum = z.enum(desiredOccupation)
export type DesiredOccupationEnum = z.infer<typeof DesiredOccupationEnum>

const desiredAgeRange = ["rango-edad-deseado"] as const
export const DesiredAgeRangeEnum = z.enum(desiredAgeRange)
export type DesiredAgeRangeEnum = z.infer<typeof DesiredAgeRangeEnum>

const minimumStay = [
  "estancia-minima-1-mes",
  "estancia-minima-2-meses",
  "estancia-minima-3-meses",
  "estancia-minima-4-meses",
  "estancia-minima-5-meses",
  "estancia-minima-mas-de-6-meses",
] as const
export const MinimumStayEnum = z.enum(minimumStay)
export type MinimumStayEnum = z.infer<typeof MinimumStayEnum>

const availableFrom = [
  "disponible-a-partir-de-ya",
  "disponible-a-partir-de-1-mes",
  "disponible-a-partir-de-2-meses",
  "disponible-a-partir-de-3-meses",
  "disponible-a-partir-de-4-meses",
  "disponible-a-partir-de-5-meses",
] as const
export const AvailableFromEnum = z.enum(availableFrom)
export type AvailableFromEnum = z.infer<typeof AvailableFromEnum>

export const PropertyFeature = [
  // ----- Dormitorios -----
  ...bedroomFeatures,
  // ----- Baños -----
  ...bathroomFeatures,
  // ----- Estacionamientos -----
  ...parkingFeatures,
  // ----- Tipo de Departamento -----
  ...apartmentTypeFeatures,
  // ----- Amueblado -----
  ...furnishedFeatures,
  // ----- Antigüedad -----
  ...buildingAgeFeatures,
  // ----- Deposito alquiler -----
  ...depositFeatures,
  // // ----- Mantenimiento -----
  ...maintenanceFeatures,
  // ----- Vista -----
  ...viewFeatures,
  // ----- Tipo de Terreno -----
  ...terrainTypeFeatures,
  // ----- Características -----
  ...extrasFeatures,
  // ----- Planta -----
  ...floorFeatures,
  // ----- Puerta -----
  ...doorFeatures,
  // ----- Tipo de Terreno -----
  ...terrainTypeFeatures,
  // ----- Ambientes -----
  ...roomsFeatures,
  // ----- Escaparates -----
  ...shopWindowsFeatures,
  // ----- Estado de la propiedad (local comercial) -----
  ...propertyConditions,
  // ----- Ubicación del local comercial -----
  ...commercialLocations,
  // ----- Actividad comercial -----
  ...commercialActivities,
  // ----- Nº de plantas -----
  ...totalFloorFeatures,
  // ----- Fumadores -----
  ...smokersFeatures,
  // ----- Mascotas -----
  ...petsFeatures,
  // ----- Se aceptan parejas -----
  ...couplesAllowedFeatures,
  // ----- Se aceptan menores de edad -----
  ...minorsAllowedFeatures,
  // ----- Habitación amoblada -----
  ...bedroomIsFurnishedFeatures,
  // ----- Baño privado -----
  ...bedroomBathroomFeatures,
  // ----- Personas totales en vivienda -----
  ...totalPeopleInHouseFeatures,
  // ----- Géneros de las personas que viven actualmente -----
  ...genderOfPeopleCurrentlyLiving,
  // ----- Ocupación profesional -----
  ...professionalOccupation,
  // ----- Quién vive actualmente -----
  ...whoLivesCurrently,
  // ----- Propietario vive en la vivienda -----
  ...ownerLivesInHouse,
  // ----- Convivencia -----
  ...coexistence,
  // ----- Género deseado -----
  ...desiredGender,
  // ----- Ocupación deseada -----
  ...desiredOccupation,
  // ----- Rango de edad deseado -----
  ...desiredAgeRange,
  // ----- Estancia mínima -----
  ...minimumStay,
  // ----- Disponible a partir de -----
  ...availableFrom,
] as const
export const FeatureEnum = z.enum(PropertyFeature)
export type FeatureEnum = z.infer<typeof FeatureEnum>

export const FeatureValueType = ["string", "number", "boolean"] as const
export const FeatureValueTypeEnum = z.enum(FeatureValueType)
export type FeatureValueTypeEnum = z.infer<typeof FeatureValueTypeEnum>

export const PropertyStatus = [
  "draft",
  "in-review",
  "rejected", // Descartado por calidad, el usuario no puede realizar ninguna acción sobre un anuncio en estado "rejected", pero siempre se le mostrará en su lista de anuncios.
  "need-changes",
  "active", // Activo, el anuncio está publicado y visible para los usuarios.
  "hidden", // Oculto, el anuncio está oculto y no es visible para los usuarios.
  /**
   * Finalizado, el anuncio ha sido vendido o alquilado y no es visible para los usuarios.
   * El usuario recibirá una notificación X días antes de que el anuncio caduque.
   * El usuario podrá renovar/recuperar el anuncio. Si el anuncio está dentro del mes pagado, la renovación será gratuita e inmediata.
   * Si el anuncio está fuera del mes pagado, el usuario deberá pagar la renovación por otros 30 días (o el tiempo que desee).
   */
  "finalized",
  "deleted", // Si por alguna razón queremos realizar un borrado lógico. Estos anuncios no los verán ni los buscadores ni los publicadores.
] as const
export const PropertyStatusEnum = z.enum(PropertyStatus)
export type PropertyStatusEnum = z.infer<typeof PropertyStatusEnum>

const areaTypes = ["total", "techado", "edificable", "útil"] as const
export const AreaTypeEnum = z.enum(areaTypes)
export type AreaTypeEnum = z.infer<typeof AreaTypeEnum>
