import {
  Department,
  District,
  Neighborhood,
  Property,
  Province,
} from "@/db/schemas"
import { PROPERTY_TYPES } from "@/db/schemas/constants/property-types"
import { TRANSACTION_TYPES } from "@/db/schemas/constants/transaction-types"
import {
  ApartmentTypeFeaturesEnum,
  AvailableFromEnum,
  BathroomFeaturesEnum,
  BedroomBathroomFeaturesEnum,
  BedroomFeaturesEnum,
  BedroomIsFurnishedFeaturesEnum,
  BuildingAgeFeaturesEnum,
  CoexistenceEnum,
  CommercialActivityEnum,
  CommercialLocationEnum,
  CouplesAllowedFeaturesEnum,
  DepositFeaturesEnum,
  DesiredGenderEnum,
  DesiredOccupationEnum,
  ExtrasFeaturesEnum,
  FurnishedFeaturesEnum,
  GenderOfPeopleCurrentlyLivingEnum,
  MinimumStayEnum,
  MinorsAllowedFeaturesEnum,
  OwnerLivesInHouseEnum,
  // MaintenanceFeaturesEnum,
  ParkingFeaturesEnum,
  PetsFeaturesEnum,
  ProfessionalOccupationEnum,
  PropertyConditionEnum,
  PropertyStatusEnum,
  PropertyTypeEnum,
  RoomsFeaturesEnum,
  SmokersFeaturesEnum,
  // ShopWindowsFeaturesEnum,
  TerrainTypeFeaturesEnum,
  TotalFloorsFeaturesEnum,
  TransactionTypeEnum,
  // TypeOfBedFeaturesEnum,
  ViewFeaturesEnum,
  WhoLivesCurrentlyEnum,
} from "@/db/schemas/enums"
import { z } from "zod"

import {
  userPrivateMetadataSchema,
  userPublicMetadataSchema,
} from "@/lib/validations/metadata"
import { type Icons } from "@/components/icons"

export interface NavItem {
  title: string
  href?: string
  disabled?: boolean
  external?: boolean
  icon?: keyof typeof Icons
  label?: string
  description?: string
}

export interface NavItemWithChildren extends NavItem {
  items: NavItemWithChildren[]
}

export interface NavItemWithOptionalChildren extends NavItem {
  items?: NavItemWithChildren[]
}

export interface FooterItem {
  title: string
  items: {
    title: string
    href: string
    external?: boolean
  }[]
}

export type MainNavItem = NavItemWithOptionalChildren

export type SidebarNavItem = NavItemWithChildren

export type UserRole = z.infer<typeof userPrivateMetadataSchema.shape.role>

export type MembershipPlan = z.infer<
  typeof userPublicMetadataSchema.shape.membershipPlan
>

// export type FileWithPreview = FileWithPath & {
//   preview: string
// }

export class PropertyFilters {
  readonly isBookmarked?: boolean
  readonly extras?: ExtrasFeaturesEnum[]
  readonly bedrooms?: BedroomFeaturesEnum[]
  readonly bathrooms?: BathroomFeaturesEnum[]
  readonly parkings?: ParkingFeaturesEnum[]
  readonly rooms?: RoomsFeaturesEnum[]
  readonly totalFloors?: TotalFloorsFeaturesEnum[]
  // readonly shopWindows?: ShopWindowsFeaturesEnum[]
  readonly apartmentType?: ApartmentTypeFeaturesEnum[]
  readonly terrainType?: TerrainTypeFeaturesEnum[]
  readonly furnished?: FurnishedFeaturesEnum[]
  readonly deposit?: DepositFeaturesEnum[]
  readonly condition?: PropertyConditionEnum[]
  readonly commercialLocation?: CommercialLocationEnum[]
  readonly previousCommercialActivity?: CommercialActivityEnum[]
  readonly smokingAllowed?: SmokersFeaturesEnum[]
  readonly petsAllowed?: PetsFeaturesEnum[]
  readonly couplesAllowed?: CouplesAllowedFeaturesEnum[]
  readonly minorsAllowed?: MinorsAllowedFeaturesEnum[]
  // readonly typeOfBed?: TypeOfBedFeaturesEnum[]
  readonly bedroomIsFurnished?: BedroomIsFurnishedFeaturesEnum[]
  readonly bedroomBathroom?: BedroomBathroomFeaturesEnum[]
  readonly desiredGender?: DesiredGenderEnum[]
  // readonly maintenance?: MaintenanceFeaturesEnum
  readonly views?: ViewFeaturesEnum[]
  readonly status?: PropertyStatusEnum
  readonly propertyType?: PropertyTypeEnum
  propertyTypeId?: number
  readonly transactionType?: TransactionTypeEnum
  transactionTypeId?: number
  readonly priceMin?: number
  readonly priceMax?: number
  readonly priceDollarMin?: number
  readonly priceDollarMax?: number
  readonly includeMaintenancePrice?: boolean
  // readonly maintenancePriceMin?: number
  // readonly maintenancePriceMax?: number
  readonly totalSurfaceMin?: number
  readonly totalSurfaceMax?: number
  readonly coveredSurfaceMin?: number
  readonly coveredSurfaceMax?: number
  readonly buildableSurfaceMin?: number
  readonly buildableSurfaceMax?: number
  readonly usableSurfaceMin?: number
  readonly usableSurfaceMax?: number
  readonly departmentId?: string
  readonly provinceId?: string
  readonly districtId?: string
  readonly neighborhoodId?: string

  getTransactionTypeId?() {
    if (this.transactionTypeId) return this.transactionTypeId
    if (!this.transactionType) return undefined
    const transactionTypeId = TRANSACTION_TYPES.find(
      (p) => p.key === this.transactionType
    )?.id
    if (!transactionTypeId) throw new Error("Invalid transaction type")
    return transactionTypeId
  }

  getPropertyTypeId?() {
    if (this.propertyTypeId) return this.propertyTypeId
    if (!this.propertyType) return undefined
    const propertyTypeId = PROPERTY_TYPES.find(
      (p) => p.key === this.propertyType
    )?.id
    if (!propertyTypeId) throw new Error("Invalid property type")
    return propertyTypeId
  }

  constructor(filters: PropertyFilters) {
    this.isBookmarked = filters?.isBookmarked
    this.extras = filters?.extras ?? []
    this.bedrooms = filters?.bedrooms ?? []
    this.bathrooms = filters?.bathrooms ?? []
    this.parkings = filters?.parkings ?? []
    this.rooms = filters?.rooms ?? []
    this.totalFloors = filters?.totalFloors ?? []
    // this.shopWindows = filters?.shopWindows ?? []
    this.apartmentType = filters?.apartmentType ?? []
    this.terrainType = filters?.terrainType ?? []
    this.views = filters?.views ?? []
    this.furnished = filters?.furnished ?? []
    this.deposit = filters?.deposit ?? []
    this.condition = filters?.condition ?? []
    this.commercialLocation = filters?.commercialLocation ?? []
    this.previousCommercialActivity = filters?.previousCommercialActivity ?? []
    this.smokingAllowed = filters?.smokingAllowed ?? []
    this.petsAllowed = filters?.petsAllowed ?? []
    this.couplesAllowed = filters?.couplesAllowed ?? []
    this.minorsAllowed = filters?.minorsAllowed ?? []
    // this.typeOfBed = filters?.typeOfBed ?? []
    this.bedroomIsFurnished = filters?.bedroomIsFurnished ?? []
    this.bedroomBathroom = filters?.bedroomBathroom ?? []
    this.desiredGender = filters?.desiredGender ?? []
    // this.maintenance = filters?.maintenance
    this.status = filters?.status ?? PropertyStatusEnum.Values.active
    this.propertyType = filters?.propertyType
    this.propertyTypeId = this.getPropertyTypeId && this.getPropertyTypeId()
    this.transactionType = filters?.transactionType
    this.transactionTypeId =
      this.getTransactionTypeId && this.getTransactionTypeId()
    this.priceMin = filters?.priceMin
    this.priceMax = filters?.priceMax
    this.priceDollarMin = filters?.priceDollarMin
    this.priceDollarMax = filters?.priceDollarMax
    this.includeMaintenancePrice = filters?.includeMaintenancePrice
    // this.maintenancePriceMin = filters?.maintenancePriceMin
    // this.maintenancePriceMax = filters?.maintenancePriceMax
    this.totalSurfaceMin = filters?.totalSurfaceMin
    this.totalSurfaceMax = filters?.totalSurfaceMax
    this.coveredSurfaceMin = filters?.coveredSurfaceMin
    this.coveredSurfaceMax = filters?.coveredSurfaceMax
    this.buildableSurfaceMin = filters?.buildableSurfaceMin
    this.buildableSurfaceMax = filters?.buildableSurfaceMax
    this.usableSurfaceMin = filters?.usableSurfaceMin
    this.usableSurfaceMax = filters?.usableSurfaceMax
    this.departmentId = filters?.departmentId
    this.provinceId = filters?.provinceId
    this.districtId = filters?.districtId
    this.neighborhoodId = filters?.neighborhoodId
  }
}

export class PropertiesByUserFilters {
  readonly status?: PropertyStatusEnum
  readonly transactionType?: TransactionTypeEnum

  constructor({
    status,
    transactionType,
  }: {
    status?: PropertyStatusEnum
    transactionType?: TransactionTypeEnum
  }) {
    this.status = status
    this.transactionType = transactionType
  }
}

type SortFieldType = keyof Pick<
  Property,
  "price" | "createdAt" | "totalSurface" | "score"
>
export const sortField: SortFieldType[] = [
  "price",
  "createdAt",
  "totalSurface",
  "score",
]
export const SortFieldEnum = z.enum(sortField as [SortFieldType])
export type SortFieldEnum = z.infer<typeof SortFieldEnum>

export class PropertySort {
  readonly field: SortFieldEnum
  readonly direction?: "ASC" | "DESC"

  constructor(
    field: SortFieldEnum = SortFieldEnum.Values.price as SortFieldEnum,
    direction: "ASC" | "DESC" = "ASC"
  ) {
    this.field = field
    this.direction = direction
  }
}

const SearchParamsKey = {
  page: "pagina",
  perPage: "por_pagina",
  sort: "ordenar_por",
  transactionType: "tipo_transaccion",
  propertyType: "tipo_propiedad",
  apartmentType: "tipo_departamento",
  furnished: "amueblado",
  views: "vistas",
  deposit: "garantia",
  currency: "divisa",
  minPrice: "precio_min",
  maxPrice: "precio_max",
  area: "area",
  maxArea: "area_max",
  minArea: "area_min",
  terrainType: "tipo_terreno",
  features: "caracteristicas",
  extras: "extras",
  placeId: "place_id",
  commercialLocation: "ubicacion_comercial",
  commercialActivity: "actividad_comercial",
  condition: "estado",
  smokers: "fumadores",
  pets: "mascotas",
  couples: "parejas",
  minors: "menores",
  // typeOfBed: "tipo_de_cama",
  bedroomIsFurnished: "habitacion_amoblada",
  bedroomBathroom: "habitacion_bano",
  desiredGender: "genero_deseado",
  availableFrom: "disponible_a_partir_de",
} as const
export const SearchParamsKeyEnum = z.nativeEnum(SearchParamsKey)
export type SearchParamsKeyEnum = z.infer<typeof SearchParamsKeyEnum>

const YourAdsParamsKey = {
  transactionType: "tipo_transaccion",
  status: "estado",
} as const
export const YourAdsParamsKeyEnum = z.nativeEnum(YourAdsParamsKey)
export type YourAdsParamsKeyEnum = z.infer<typeof YourAdsParamsKeyEnum>

const SortOption = [
  "relevancia",
  "baratos",
  "caros",
  "recientes",
  "antiguos",
] as const
export const SortOptionEnum = z.enum(SortOption)
export type SortOptionEnum = z.infer<typeof SortOptionEnum>

export class Pagination {
  readonly page: number
  readonly pageSize: number

  constructor(
    page: number = 1, // Non zero, starting from 1
    pageSize: number = 10
  ) {
    this.page = page
    this.pageSize = pageSize
  }
}

export class Paginated<T> {
  readonly data: T[]
  readonly page: number
  readonly pageSize: number
  readonly total: number
  readonly totalPages: number

  constructor(
    data: T[] = [],
    page: number = 1, // Non zero, starting from 1
    pageSize: number = 10,
    total: number = 0
  ) {
    this.data = data
    this.page = page
    this.pageSize = pageSize
    this.total = total
    this.totalPages = Math.ceil(total / pageSize)
  }
}

export type ParsedFeatures = {
  bedrooms?: number
  bathrooms?: number
  parking?: number
  rooms?: number
  totalFloors?: number
  // shopWindows?: number
  apartmentType?: ApartmentTypeFeaturesEnum
  furnished?: FurnishedFeaturesEnum
  buildingAge?: BuildingAgeFeaturesEnum
  buildingYears?: number
  deposit?: DepositFeaturesEnum | null
  views?: ViewFeaturesEnum
  maintenanceIncluded?: boolean
  maintenancePrice?: number | null
  terrainType?: TerrainTypeFeaturesEnum
  condition?: PropertyConditionEnum
  commercialLocation?: CommercialLocationEnum | null
  previousCommercialActivity?: CommercialActivityEnum | null
  smokingAllowed?: SmokersFeaturesEnum | null
  petsAllowed?: PetsFeaturesEnum | null
  couplesAllowed?: CouplesAllowedFeaturesEnum | null
  minorsAllowed?: MinorsAllowedFeaturesEnum | null
  // typeOfBed?: TypeOfBedFeaturesEnum | null
  bedroomIsFurnished?: BedroomIsFurnishedFeaturesEnum | null
  bedroomBathroom?: BedroomBathroomFeaturesEnum | null
  availableFrom?: AvailableFromEnum | null
  totalNumberOfPeople?: number | null
  whoLivesCurrently?: WhoLivesCurrentlyEnum | null
  ageRangeOfPersonLiving?: [number, number] | null
  genderOfPeopleCurrentlyLiving?: GenderOfPeopleCurrentlyLivingEnum | null
  professionalOccupation?: ProfessionalOccupationEnum | null
  ownerLivesInHouse?: OwnerLivesInHouseEnum | null
  coexistence?: CoexistenceEnum | null
  desiredGender?: DesiredGenderEnum | null
  desiredAgeRange?: [number, number] | null
  desiredOccupation?: DesiredOccupationEnum | null
  minimumStay?: MinimumStayEnum | null
  features: ExtrasFeaturesEnum[]
}

export type ContactMethod = "phone" | "whatsapp" | "email"
// | "instagram"

export type ShareMethod =
  | "copyLink"
  | "whatsapp"
  | "facebook"
  | "facebookMessenger"

export class ParseQueriedPropertyOptions {
  readonly hideContactInfo: boolean
  readonly hideRequiredChanges: boolean
  readonly ofuscateAddress: boolean

  constructor(options?: Partial<ParseQueriedPropertyOptions>) {
    this.hideContactInfo = options?.hideContactInfo ?? true
    this.hideRequiredChanges = options?.hideRequiredChanges ?? true
    this.ofuscateAddress = options?.ofuscateAddress ?? true
  }
}

export type PublishStep = 1 | 2 | 3 | 4 | 5 | 6 | 7
export const stepPaths = {
  1: "operacion-y-propiedad",
  2: "direccion",
  3: "contacto",
  4: "caracteristicas",
  5: "extras",
  6: "media",
  7: "resumen",
}

export class LocationPlace {
  readonly id: string
  readonly type: "department" | "province" | "district" | "neighborhood"
  readonly description: string
  readonly department?: Pick<Department, "id" | "name">
  readonly province?: Pick<Province, "id" | "name">
  readonly district?: Pick<District, "id" | "name">
  readonly neighborhood?: Pick<Neighborhood, "id" | "name">

  constructor({
    department,
    province,
    district,
    neighborhood,
  }: {
    department?: Department
    province?: Province
    district?: District
    neighborhood?: Neighborhood
  }) {
    this.id = ""
    this.description = ""
    this.type = "department"
    if (neighborhood) {
      this.id = neighborhood.id
      this.neighborhood = {
        id: neighborhood.id,
        name: neighborhood.name!,
      }
      this.district = {
        id: neighborhood.districtId!,
        name: neighborhood.district!.name!,
      }
      this.province = {
        id: neighborhood.provinceId!,
        name: neighborhood.province!.name!,
      }
      this.department = {
        id: neighborhood.departmentId!,
        name: neighborhood.department!.name!,
      }
      this.type = "neighborhood"
      this.description = `${neighborhood.name}, ${
        neighborhood.district!.name
      }, ${neighborhood.province!.name}, ${neighborhood.department!.name}`
    } else if (district) {
      this.id = district.id
      this.district = {
        id: district.id,
        name: district.name!,
      }
      this.province = {
        id: district.provinceId!,
        name: district.province!.name!,
      }
      this.department = {
        id: district.departmentId!,
        name: district.department!.name!,
      }
      this.type = "district"
      this.description = `${district.name}, ${district.province!.name}, ${
        district.department!.name
      }`
    } else if (province) {
      this.id = province.id
      this.province = {
        id: province.id,
        name: province.name!,
      }
      this.department = {
        id: province.departmentId!,
        name: province.department!.name!,
      }
      this.type = "province"
      this.description = `${province.name}, ${province.department!.name}`
    } else if (department) {
      this.id = department.id
      this.department = {
        id: department.id,
        name: department.name!,
      }
      this.type = "department"
      this.description = `${department.name}`
    }
  }
}

export type UploadImageResponse = {
  name: string
  success: boolean
  id?: string
  src?: string
}

export type WithLabel<T> = T & {
  label: string
  pluralLabel?: string
}
