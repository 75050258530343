import { TransactionType } from "@/db/schemas/transaction-type.schema"

export const TRANSACTION_TYPES: TransactionType[] = [
  {
    id: 1,
    key: "alquiler",
  },
  {
    id: 2,
    key: "venta",
  },
]
