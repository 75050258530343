import { PropertyType } from "@/db/schemas/property-type.schema"
import { WithLabel } from "@/types"

import { PropertyTypeEnum } from "../enums"

export const PROPERTY_TYPES: WithLabel<PropertyType>[] = [
  {
    id: 1,
    key: PropertyTypeEnum.Values.departamento,
    label: "Departamento",
    pluralLabel: "Departamentos",
  },
  {
    id: 2,
    key: PropertyTypeEnum.Values.casa,
    label: "Casa",
    pluralLabel: "Casas",
  },
  {
    id: 3,
    key: PropertyTypeEnum.Values.terreno,
    label: "Terreno",
    pluralLabel: "Terrenos",
  },
  {
    id: 4,
    key: PropertyTypeEnum.Values["local-comercial"],
    label: "Local Comercial",
    pluralLabel: "Locales Comerciales",
  },
  {
    id: 5,
    key: PropertyTypeEnum.Values.oficina,
    label: "Oficina",
    pluralLabel: "Oficinas",
  },
  {
    id: 6,
    key: PropertyTypeEnum.Values["habitacion"],
    label: "Habitación",
    pluralLabel: "Habitaciones",
  },
]
